import { useForm, SubmitHandler } from "react-hook-form";
import { zodNewsletterSchema } from "../models/zodFormModel";
import { zodResolver } from "@hookform/resolvers/zod";
import { useGameContext } from "../context/useGameContext";
import { useEffect } from "react";
import { z } from "zod";
import {
  doNotShowNewsletterModal,
  doNewsletterModalHasBeenShown,
} from "../utilities/localStorage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { subscribeToNewsletter } from "../utilities/subscribeToNewsletter";
import { useCookieContext } from "../context/useCookieContext";

type NewsletterFormTypes = z.infer<typeof zodNewsletterSchema>;

export const NewsletterSubscribeModal = () => {
  const {
    isNewsletterModalOpen,
    setIsNewsletterModalOpen,
    setNewsletterSuccessModal,
    ip,
  } = useGameContext();

  const { isCookiesModalOpen, isCookiesPreferenceModalOpen } =
    useCookieContext();

  useEffect(() => {
    const otherModalsAreOpen =
      isCookiesModalOpen || isCookiesPreferenceModalOpen;

    const newsletter = doNewsletterModalHasBeenShown();
    if (!otherModalsAreOpen && !newsletter) {
      const timer = setTimeout(() => {
        setIsNewsletterModalOpen(true);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [
    isCookiesModalOpen,
    isCookiesPreferenceModalOpen,
    setIsNewsletterModalOpen,
  ]);

  const toggleModal = () => {
    setIsNewsletterModalOpen(!isNewsletterModalOpen);
    localStorage.setItem("newsletter", "true");
    doNotShowNewsletterModal();
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<NewsletterFormTypes>({
    resolver: zodResolver(zodNewsletterSchema),
  });

  const onSubmit: SubmitHandler<NewsletterFormTypes> = async (userData) => {
    try {
      const mailerLiteResponse = await subscribeToNewsletter(userData, ip);
      const userStatus = mailerLiteResponse?.data.data.status;
      if (userStatus === "newUsedAdded" || userStatus === "unconfirmed") {
        setIsNewsletterModalOpen(false);
        setNewsletterSuccessModal(true);
        toast.warning("Zapisałeś/aś sie do newslettera, potwierdź maila!");
      } else {
        setIsNewsletterModalOpen(false);
        toast.success("Jesteś już w bazie newsletter'a!");
      }
    } catch (error) {
      toast.error("Coś poszło nie tak, spróbuj jeszczę raz");
    }
  };

  return (
    <section className={`modal ${isNewsletterModalOpen ? "is-active" : ""}`}>
      <div className="modal-background" onClick={toggleModal}></div>

      <div className="modal-card">
        <div className="modal-card-body ">
          <button
            className="delete is-pulled-right"
            onClick={toggleModal}
            aria-label="close"
          ></button>

          <div className="newsletter ">
            <p className="title is-4 ">
              Zapisz się i zgarnij kod rabatowy -25% ✨
            </p>
            <p className="title is-6">Zero spamu, tylko ważne informacje!</p>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
              <div className="field  ">
                <div className="control is-expanded has-icons-left field">
                  <input
                    {...register("userFirstName")}
                    type="userFirstName"
                    name="userFirstName"
                    placeholder="Twoje imię"
                    className={`input `}
                  />
                  <span className="icon is-small is-left">
                    <FontAwesomeIcon icon={faUser} />
                  </span>
                </div>
                <div className="control is-expanded has-icons-left field">
                  <input
                    {...register("userEmail")}
                    type="userEmail"
                    name="userEmail"
                    placeholder="Twój główny email"
                    className={`input ${errors.userEmail ? "is-danger" : ""}`}
                  />
                  <span className="icon is-small is-left">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </span>
                  {errors.userEmail && (
                    <div className="is-warning">{errors.userEmail.message}</div>
                  )}
                </div>
                <p className="control">
                  <button className="button is-primary" type="submit">
                    Zapisuję się!
                  </button>
                </p>
              </div>
              <p className="is-size-7">
                Klikając „Zapisuje się!” wyrażasz zgodę na otrzymywanie
                informacji o nowych konkursach i usługach oraz na przetwarzanie
                danych zgodnie z
                <Link to="/polityka">
                  <span className="underline font-semibold p-1">
                    Polityką Prywatności.
                  </span>
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
