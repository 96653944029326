import { useGameContext } from "../context/useGameContext";
import { doNotShowNewsletterModal } from "../utilities/localStorage";

export const NewsletterSuccessModal = () => {
  const { newsletterSuccessModal, setNewsletterSuccessModal } =
    useGameContext();

  const toggleModal = () => {
    setNewsletterSuccessModal(!newsletterSuccessModal);
    doNotShowNewsletterModal();
  };

  const thanksImage = require("../assets/images/img-confirmation-newsletter.png");

  return (
    <section className={`modal ${newsletterSuccessModal ? "is-active" : ""}`}>
      <div className="modal-background" onClick={toggleModal}></div>
      <div className="modal-card ">
        <div className="modal-card-body">
          <button
            className="delete is-pulled-right"
            onClick={toggleModal}
            aria-label="close"
          ></button>
          <div>
            <p className=" is-size-6">
              Super! 🚀 Już prawie mamy to! Wkrótce otrzymasz od nas e-maila.
            </p>
            <br />
            <p className="is-size-5">
              ✨ Po zaakceptowaniu otrzymasz
              <strong> kod rabatowy -25%</strong>
            </p>
            <img
              src={thanksImage}
              alt="thank you"
              className="emailConfirmationImg"
            />
            <p className="is-size-6">
              Otwórz Twojego maila i kliknij w link aktywacyjny od nas. 📩
              Sprawdź folder SPAM!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
