// DODAC RECHAPTA DO NEWSLETTERÓW

import { Link } from "react-router-dom";
import { Navbar } from "../components/Navbar";
import { Timer } from "../components/Timer";
import { CookiesModal } from "../components/CookiesModal";
import { CookiesModalPreferences } from "../components/CookiesModalPreferences";
import NewsletterElement from "../components/NewsletterElement";
import { HomeBanner } from "../components/HomeBanner";
import { useGameContext } from "../context/useGameContext";
import { WinnersElement } from "../components/WinnersElement";
import { NewsletterSubscribeModal } from "../components/NewsletterSubscribeModal";
import { NewsletterSuccessModal } from "../components/NewsletterSuccessModal";
import ButtonPlay from "../components/ButtonPlay";
import { InstructionsElement } from "../components/InstructionsElement";
import { ShelterElement } from "../components/ShelterBox";

export const Home = () => {
  const { isGameRunning } = useGameContext();

  return (
    <>
      <div className="homeBanner">
        <HomeBanner />
      </div>
      <div className="homeContainer">
        <InstructionsElement />
        <Timer />
        <section className="section buttonSection">
          {isGameRunning ? (
            <Link to="/lobby">
              <ButtonPlay title="Weź udział" />
            </Link>
          ) : (
            <Link to="/nogame">
              <ButtonPlay title="Weź udział" />
            </Link>
          )}
        </section>

        <ShelterElement />

        <NewsletterElement
          title="Zdobądź przewagę i zgarnij kod -25%"
          description1="zapisz się do newsletter'a i dowiaduj się pierwszy o naszych nowych
          akcjach"
          description2=""
          buttonText="Odbierz kod promocyjny"
        />

        <WinnersElement />
      </div>
      <CookiesModal />
      <CookiesModalPreferences />

      <NewsletterSubscribeModal />
      <NewsletterSuccessModal />
    </>
  );
};
